// Generated by Framer (7699a62)

import { addFonts, cx, CycleVariantState, SVG, useConstant, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./v3MbDkr82-0.js";

const cycleOrder = ["m8IycVHqy"];

const serializationHash = "framer-ZV9u2"

const variantClassNames = {m8IycVHqy: "framer-v-1nk7u44"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const initialVariant = useConstant(() => variant)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "m8IycVHqy", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1nk7u44", className, classNames)} data-framer-name={"Variant 1"} initial={initialVariant} layoutDependency={layoutDependency} layoutId={"m8IycVHqy"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-2cgjz2"} data-framer-name={"Group"} fill={"rgba(0,0,0,1)"} intrinsicHeight={34} intrinsicWidth={34} layoutDependency={layoutDependency} layoutId={"a0EvNIlSx"} svg={"<svg width=\"34\" height=\"34\" viewBox=\"0 0 34 34\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M17 33.9834C26.3 33.9834 34 26.2705 34 16.9917C34 7.69623 26.2833 0 16.9833 0C7.69999 0 0 7.69623 0 16.9917C0 26.2705 7.71666 33.9834 17 33.9834ZM13.8333 23.7051C13.0333 24.1882 12.1166 23.805 12.1166 22.9721V11.0279C12.1166 10.2117 13.1 9.86183 13.8333 10.2949L23.6167 16.0921C24.3166 16.5086 24.3333 17.508 23.6167 17.9412L13.8333 23.7051Z\" fill=\"black\"/>\n</svg>\n"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-ZV9u2[data-border=\"true\"]::after, .framer-ZV9u2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZV9u2.framer-h04641, .framer-ZV9u2 .framer-h04641 { display: block; }", ".framer-ZV9u2.framer-1nk7u44 { height: 40px; overflow: hidden; position: relative; width: 40px; }", ".framer-ZV9u2 .framer-2cgjz2 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerv3MbDkr82: React.ComponentType<Props> = withCSS(Component, css, "framer-ZV9u2") as typeof Component;
export default Framerv3MbDkr82;

Framerv3MbDkr82.displayName = "play-mouse";

Framerv3MbDkr82.defaultProps = {height: 40, width: 40};

addFonts(Framerv3MbDkr82, [])